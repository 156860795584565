@font-face {
    font-family: "Gotham-mac";
    src: url("./gothambold-webfont.woff2") format("woff2"),
        url("./gothambold-webfont.woff") format("woff");
    font-weight: 700;
}

@font-face {
    font-family: "Gotham-mac";
    src: url("./gothambook-webfont.woff2") format("woff2"),
        url("./gothambook-webfont.woff") format("woff");
    font-weight: 400;
}

@font-face {
    font-family: "Gotham-mac";
    src: url("./gothamlight-webfont.woff2") format("woff2"),
        url("./gothamlight-webfont.woff") format("woff");
    font-weight: 300;
}

@font-face {
    font-family: "Gotham-mac";
    src: url("./gothammedium-webfont.woff2") format("woff2"),
        url("./gothammedium-webfont.woff") format("woff");
    font-weight: 500;
}
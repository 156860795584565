@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 400;
    src: local('Gotham'), url('./GothamBook.woff') format('woff');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 400;
    src: local('Gotham'), url('./GothamBookItalic.woff') format('woff');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 300;
    src: local('Gotham'), url('./GothamLight.woff') format('woff');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 300;
    src: local('Gotham'), url('./GothamLightItalic.woff') format('woff');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    src: local('Gotham'), url('./GothamMedium.woff') format('woff');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 500;
    src: local('Gotham'), url('./GothamMedium_1.woff') format('woff');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 500;
    src: local('Gotham'), url('./GothamMediumItalic.woff') format('woff');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    src: local('Gotham'), url('./GothamBold.woff') format('woff');
}

@font-face {
    font-family: 'Gotham';
    font-style: normal;
    font-weight: 700;
    src: local('Gotham'), url('./Gotham-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Gotham';
    font-style: italic;
    font-weight: 700;
    src: local('Gotham'), url('./GothamBoldItalic.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Black';
    font-style: normal;
    font-weight: 500;
    src: local('Gotham Black'), url('./Gotham-Black.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Light';
    font-style: normal;
    font-weight: 500;
    src: local('Gotham Light'), url('./Gotham-Light.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Thin';
    font-style: normal;
    font-weight: 500;
    src: local('Gotham Thin'), url('./Gotham-Thin.woff') format('woff');
}

@font-face {
    font-family: 'Gotham XLight';
    font-style: normal;
    font-weight: 500;
    src: local('Gotham XLight'), url('./Gotham-XLight.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Book';
    font-style: italic;
    font-weight: 500;
    src: local('Gotham Book'), url('./Gotham-BookItalic.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Thin';
    font-style: italic;
    font-weight: 500;
    src: local('Gotham Thin'), url('./Gotham-ThinItalic.woff') format('woff');
}

@font-face {
    font-family: 'Gotham Ultra';
    font-style: italic;
    font-weight: 500;
    src: local('Gotham Ultra'), url('./Gotham-UltraItalic.woff') format('woff');
}

@font-face {
    font-family: 'Gotham XLight';
    font-style: italic;
    font-weight: 500;
    src: local('Gotham XLight'), url('./Gotham-XLightItalic.woff') format('woff');
}
.no-p {
    padding: 0px !important
}

.no-p-t {
    padding-top: 0px !important
}

.no-p-r {
    padding-right: 0px !important
}

.no-p-b {
    padding-bottom: 0px !important
}

.no-p-l {
    padding-left: 0px !important
}

.no-p-v {
    padding: 0px 0 !important
}

.no-p-h {
    padding: 0 0px !important
}

.p-5 {
    padding: 5px
}

.p-t-5 {
    padding-top: 5px
}

.p-r-5 {
    padding-right: 5px
}

.p-b-5 {
    padding-bottom: 5px
}

.p-l-5 {
    padding-left: 5px
}

.p-v-5 {
    padding: 5px 0
}

.p-h-5 {
    padding: 0 5px
}

.p-10 {
    padding: 10px
}

.p-t-10 {
    padding-top: 10px
}

.p-r-10 {
    padding-right: 10px
}

.p-b-10 {
    padding-bottom: 10px
}

.p-l-10 {
    padding-left: 10px
}

.p-v-10 {
    padding: 10px 0
}

.p-h-10 {
    padding: 0 10px
}

.p-15 {
    padding: 15px
}

.p-t-15 {
    padding-top: 15px
}

.p-r-15 {
    padding-right: 15px
}

.p-b-15 {
    padding-bottom: 15px
}

.p-l-15 {
    padding-left: 15px
}

.p-v-15 {
    padding: 15px 0
}

.p-h-15 {
    padding: 0 15px
}

.p-20 {
    padding: 20px
}

.p-t-20 {
    padding-top: 20px
}

.p-r-20 {
    padding-right: 20px
}

.p-b-20 {
    padding-bottom: 20px
}

.p-l-20 {
    padding-left: 20px
}

.p-v-20 {
    padding: 20px 0
}

.p-h-20 {
    padding: 0 20px
}

.p-25 {
    padding: 25px
}

.p-t-25 {
    padding-top: 25px
}

.p-r-25 {
    padding-right: 25px
}

.p-b-25 {
    padding-bottom: 25px
}

.p-l-25 {
    padding-left: 25px
}

.p-v-25 {
    padding: 25px 0
}

.p-h-25 {
    padding: 0 25px
}

.p-30 {
    padding: 30px
}

.p-t-30 {
    padding-top: 30px
}

.p-r-30 {
    padding-right: 30px
}

.p-b-30 {
    padding-bottom: 30px
}

.p-l-30 {
    padding-left: 30px
}

.p-v-30 {
    padding: 30px 0
}

.p-h-30 {
    padding: 0 30px
}

.p-35 {
    padding: 35px
}

.p-t-35 {
    padding-top: 35px
}

.p-r-35 {
    padding-right: 35px
}

.p-b-35 {
    padding-bottom: 35px
}

.p-l-35 {
    padding-left: 35px
}

.p-v-35 {
    padding: 35px 0
}

.p-h-35 {
    padding: 0 35px
}

.p-40 {
    padding: 40px
}

.p-t-40 {
    padding-top: 40px
}

.p-r-40 {
    padding-right: 40px
}

.p-b-40 {
    padding-bottom: 40px
}

.p-l-40 {
    padding-left: 40px
}

.p-v-40 {
    padding-top: 40px;
    padding-bottom: 40px
}

.p-h-40 {
    padding: 0 40px
}

.p-45 {
    padding: 45px
}

.p-t-45 {
    padding-top: 45px
}

.p-r-45 {
    padding-right: 45px
}

.p-b-45 {
    padding-bottom: 45px
}

.p-l-45 {
    padding-left: 45px
}

.p-v-45 {
    padding: 45px 0
}

.p-h-45 {
    padding: 0 45px
}

.p-50 {
    padding: 50px
}

.p-t-50 {
    padding-top: 50px
}

.p-r-50 {
    padding-right: 50px
}

.p-b-50 {
    padding-bottom: 50px
}

.p-l-50 {
    padding-left: 50px
}

.p-v-50 {
    padding: 50px 0
}

.p-h-50 {
    padding: 0 50px
}

.p-55 {
    padding: 55px
}

.p-t-55 {
    padding-top: 55px
}

.p-r-55 {
    padding-right: 55px
}

.p-b-55 {
    padding-bottom: 55px
}

.p-l-55 {
    padding-left: 55px
}

.p-v-55 {
    padding: 55px 0
}

.p-h-55 {
    padding: 0 55px
}

.p-60 {
    padding: 60px
}

.p-t-60 {
    padding-top: 60px
}

.p-r-60 {
    padding-right: 60px
}

.p-b-60 {
    padding-bottom: 60px
}

.p-l-60 {
    padding-left: 60px
}

.p-v-60 {
    padding: 60px 0
}

.p-h-60 {
    padding: 0 60px
}

.p-65 {
    padding: 65px
}

.p-t-65 {
    padding-top: 65px
}

.p-r-65 {
    padding-right: 65px
}

.p-b-65 {
    padding-bottom: 65px
}

.p-l-65 {
    padding-left: 65px
}

.p-v-65 {
    padding: 65px 0
}

.p-h-65 {
    padding: 0 65px
}

.p-70 {
    padding: 70px
}

.p-t-70 {
    padding-top: 70px
}

.p-r-70 {
    padding-right: 70px
}

.p-b-70 {
    padding-bottom: 70px
}

.p-l-70 {
    padding-left: 70px
}

.p-v-70 {
    padding: 70px 0
}

.p-h-70 {
    padding: 0 70px
}

.p-75 {
    padding: 75px
}

.p-t-75 {
    padding-top: 75px
}

.p-r-75 {
    padding-right: 75px
}

.p-b-75 {
    padding-bottom: 75px
}

.p-l-75 {
    padding-left: 75px
}

.p-v-75 {
    padding: 75px 0
}

.p-h-75 {
    padding: 0 75px
}

.p-80 {
    padding: 80px
}

.p-t-80 {
    padding-top: 80px
}

.p-r-80 {
    padding-right: 80px
}

.p-b-80 {
    padding-bottom: 80px
}

.p-l-80 {
    padding-left: 80px
}

.p-v-80 {
    padding: 80px 0
}

.p-h-80 {
    padding: 0 80px
}

.no-p {
    padding: 0px
}

.no-p-t {
    padding-top: 0px
}

.no-p-r {
    padding-right: 0px
}

.no-p-b {
    padding-bottom: 0px
}

.no-p-l {
    padding-left: 0px
}

.no-p-v {
    padding: 0px 0
}

.no-p-h {
    padding: 0 0px
}

.m-5 {
    margin: 5px
}

.m-t-5 {
    margin-top: 5px
}

.m-r-5 {
    margin-right: 5px
}

.m-b-5 {
    margin-bottom: 5px
}

.m-l-5 {
    margin-left: 5px
}

.m-v-5 {
    margin: 5px 0
}

.m-h-5 {
    margin: 0 5px
}

.m-10 {
    margin: 10px
}

.m-t-10 {
    margin-top: 10px
}

.m-r-10 {
    margin-right: 10px
}

.m-b-10 {
    margin-bottom: 10px
}

.m-l-10 {
    margin-left: 10px
}

.m-v-10 {
    margin: 10px 0
}

.m-h-10 {
    margin: 0 10px
}

.m-15 {
    margin: 15px
}

.m-t-15 {
    margin-top: 15px
}

.m-r-15 {
    margin-right: 15px
}

.m-b-15 {
    margin-bottom: 15px
}

.m-l-15 {
    margin-left: 15px
}

.m-v-15 {
    margin: 15px 0
}

.m-h-15 {
    margin: 0 15px
}

.m-20 {
    margin: 20px
}

.m-t-20 {
    margin-top: 20px
}

.m-r-20 {
    margin-right: 20px
}

.m-b-20 {
    margin-bottom: 20px
}

.m-l-20 {
    margin-left: 20px
}

.m-v-20 {
    margin: 20px 0
}

.m-h-20 {
    margin: 0 20px
}

.m-25 {
    margin: 25px
}

.m-t-25 {
    margin-top: 25px
}

.m-r-25 {
    margin-right: 25px
}

.m-b-25 {
    margin-bottom: 25px
}

.m-l-25 {
    margin-left: 25px
}

.m-v-25 {
    margin: 25px 0
}

.m-h-25 {
    margin: 0 25px
}

.m-30 {
    margin: 30px
}

.m-t-30 {
    margin-top: 30px
}

.m-r-30 {
    margin-right: 30px
}

.m-b-30 {
    margin-bottom: 30px
}

.m-l-30 {
    margin-left: 30px
}

.m-v-30 {
    margin: 30px 0
}

.m-h-30 {
    margin: 0 30px
}

.m-35 {
    margin: 35px
}

.m-t-35 {
    margin-top: 35px
}

.m-r-35 {
    margin-right: 35px
}

.m-b-35 {
    margin-bottom: 35px
}

.m-l-35 {
    margin-left: 35px
}

.m-v-35 {
    margin: 35px 0
}

.m-h-35 {
    margin: 0 35px
}

.m-40 {
    margin: 40px
}

.m-t-40 {
    margin-top: 40px
}

.m-r-40 {
    margin-right: 40px
}

.m-b-40 {
    margin-bottom: 40px
}

.m-l-40 {
    margin-left: 40px
}

.m-v-40 {
    margin: 40px 0
}

.m-h-40 {
    margin: 0 40px
}

.m-45 {
    margin: 45px
}

.m-t-45 {
    margin-top: 45px
}

.m-r-45 {
    margin-right: 45px
}

.m-b-45 {
    margin-bottom: 45px
}

.m-l-45 {
    margin-left: 45px
}

.m-v-45 {
    margin: 45px 0
}

.m-h-45 {
    margin: 0 45px
}

.m-50 {
    margin: 50px
}

.m-t-50 {
    margin-top: 50px
}

.m-r-50 {
    margin-right: 50px
}

.m-b-50 {
    margin-bottom: 50px
}

.m-l-50 {
    margin-left: 50px
}

.m-v-50 {
    margin: 50px 0
}

.m-h-50 {
    margin: 0 50px
}

.m-55 {
    margin: 55px
}

.m-t-55 {
    margin-top: 55px
}

.m-r-55 {
    margin-right: 55px
}

.m-b-55 {
    margin-bottom: 55px
}

.m-l-55 {
    margin-left: 55px
}

.m-v-55 {
    margin: 55px 0
}

.m-h-55 {
    margin: 0 55px
}

.m-60 {
    margin: 60px
}

.m-t-60 {
    margin-top: 60px
}

.m-r-60 {
    margin-right: 60px
}

.m-b-60 {
    margin-bottom: 60px
}

.m-l-60 {
    margin-left: 60px
}

.m-v-60 {
    margin: 60px 0
}

.m-h-60 {
    margin: 0 60px
}

.m-65 {
    margin: 65px
}

.m-t-65 {
    margin-top: 65px
}

.m-r-65 {
    margin-right: 65px
}

.m-b-65 {
    margin-bottom: 65px
}

.m-l-65 {
    margin-left: 65px
}

.m-v-65 {
    margin: 65px 0
}

.m-h-65 {
    margin: 0 65px
}

.m-70 {
    margin: 70px
}

.m-t-70 {
    margin-top: 70px
}

.m-r-70 {
    margin-right: 70px
}

.m-b-70 {
    margin-bottom: 70px
}

.m-l-70 {
    margin-left: 70px
}

.m-v-70 {
    margin: 70px 0
}

.m-h-70 {
    margin: 0 70px
}

.m-75 {
    margin: 75px
}

.m-t-75 {
    margin-top: 75px
}

.m-r-75 {
    margin-right: 75px
}

.m-b-75 {
    margin-bottom: 75px
}

.m-l-75 {
    margin-left: 75px
}

.m-v-75 {
    margin: 75px 0
}

.m-h-75 {
    margin: 0 75px
}

.m-80 {
    margin: 80px
}

.m-t-80 {
    margin-top: 80px
}

.m-r-80 {
    margin-right: 80px
}

.m-b-80 {
    margin-bottom: 80px
}

.m-l-80 {
    margin-left: 80px
}

.m-v-80 {
    margin: 80px 0
}

.m-h-80 {
    margin: 0 80px
}

.no-m {
    margin: 0px
}

.no-m-t {
    margin-top: 0px
}

.no-m-r {
    margin-right: 0px
}

.no-m-b {
    margin-bottom: 0px
}

.no-m-l {
    margin-left: 0px
}

.no-m-v {
    margin: 0px 0
}

.no-m-h {
    margin: 0 0px
}

.no-margin {
    margin: 0 !important
}

.no-m-lr,
.no-m-rl {
    margin-left: 0 !important;
    margin-right: 0 !important
}

.no-m-tb,
.no-m-bt {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.m-t-only {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important
}

.m-b-only {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important
}

.m-l-only {
    margin-right: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.m-r-only {
    margin-left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.m-auto {
    margin-left: auto;
    margin-right: auto
}

.no-padding {
    padding: 0 !important
}

.no-pad-lr,
.no-pad-rl {
    padding-left: 0 !important;
    padding-right: 0 !important
}

.no-pad-tb,
.no-pad-bt {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.pad-t-only {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important
}

.pad-b-only {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important
}

.pad-l-only {
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.pad-r-only {
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.no-border {
    border: none !important
}

.no-border-t {
    border-top: none !important
}

.no-border-r {
    border-right: none !important
}

.no-border-b {
    border-bottom: none !important
}

.no-border-l {
    border-left: none !important
}

.no-border-lr,
.no-border-rl {
    border-left: none !important;
    border-right: none !important
}

.no-border-tb,
.no-border-bt {
    border-top: none !important;
    border-bottom: none !important
}

.no-border-tl,
.no-border-lt {
    border-top: none !important;
    border-left: none !important
}

.no-border-tr,
.no-border-rt {
    border-top: none !important;
    border-right: none !important
}

.no-border-bl,
.no-border-lb {
    border-bottom: none !important;
    border-left: none !important
}

.no-border-br,
.no-border-rb {
    border-bottom: none !important;
    border-right: none !important
}

.border-dashed {
    border-style: dashed !important
}

.border-dotted {
    border-style: dashed !important
}

.border-black {
    border-color: black
}

.border-light-grey {
    border-color: #e5e5e5
}

.border-medium-grey {
    border-color: #cccccc
}

.border-grey {
    border-color: #999999
}

.border-dark-grey {
    border-color: #222222
}

.border-white,
.border-fff {
    border-color: #fff
}

.bg-black {
    background-color: #000
}

.bg-light-grey {
    background-color: #e5e5e5
}

.bg-medium-grey {
    background-color: #cccccc
}

.bg-grey {
    background-color: #999999
}

.bg-dark-grey {
    background-color: #222222
}

.bg-white,
.bg-fff {
    background-color: white
}

.bg-none,
.no-bg {
    background: none;
    background-image: none;
    background-color: transparent
}

.bg-facebook {
    background-color: #47639E
}

.bg-twitter {
    background-color: #77b9d8
}

.lh-1 {
    line-height: 1 !important
}

.lh-13 {
    line-height: 1.3 !important
}

.lh-15 {
    line-height: 1.5 !important
}

.bold,
.strong {
    font-weight: bold
}

.no-bold {
    font-weight: normal
}

.italic,
.em {
    font-style: italic
}

.strike {
    text-decoration: line-through
}

.normal {
    font-weight: normal;
    font-style: normal
}

.uppercase {
    text-transform: uppercase
}

.text-left {
    text-align: left !important
}

.text-center {
    text-align: center !important
}

.text-right {
    text-align: right !important
}

.mw400 {
    max-width: 400px
}

.mw500 {
    max-width: 500px
}

.mw600 {
    max-width: 600px
}

.w100 {
    font-weight: 100
}

.w200 {
    font-weight: 200
}

.w300 {
    font-weight: 300
}

.w400 {
    font-weight: 400
}

.w500 {
    font-weight: 500
}

.w600 {
    font-weight: 600
}

.w700 {
    font-weight: 700
}

.w800 {
    font-weight: 800
}

.w900 {
    font-weight: 900
}

.f9 {
    font-size: 9px
}

.f10 {
    font-size: 10px
}

.f11 {
    font-size: 11px
}

.f12 {
    font-size: 12px
}

.f13 {
    font-size: 13px
}

.f14 {
    font-size: 14px
}

.f15 {
    font-size: 15px
}

.f16 {
    font-size: 16px
}

.f17 {
    font-size: 17px
}

.f18 {
    font-size: 18px;
    line-height: 30px
}

.f19 {
    font-size: 19px
}

.f20 {
    font-size: 20px
}

.f30 {
    font-size: 30px
}

.f40 {
    font-size: 40px
}

.f50 {
    font-size: 50px
}

.f60 {
    font-size: 60px
}

.size-small {
    font-size: 75% !important
}

.size-normal {
    font-size: 100% !important
}

.size-medium {
    font-size: 125% !important
}

.size-big,
.size-large {
    font-size: 150% !important
}

.size-huge {
    font-size: 200% !important
}

.inherit {
    font: inherit
}

.no-wrap {
    white-space: nowrap
}

.auto-cell-size {
    white-space: nowrap;
    width: 1%
}

.ls-0 {
    letter-spacing: -3px;
    margin-left: 10px;
    margin-right: 10px;
    white-space: nowrap
}

.no-ul,
.no-ul:hover,
.no-ul a,
.no-ul a:hover {
    text-decoration: none
}

.color-inherit {
    color: inherit
}

.clear {
    clear: both
}

.clear:after {
    display: table;
    content: " ";
    clear: both
}

.f-left {
    float: left
}

.f-right {
    float: right
}

.f-none {
    float: none
}

.block {
    display: block !important
}

.inline {
    display: inline !important
}

.in-block {
    display: inline-block !important;
    display: inline !important;
    zoom: 1
}

.d-none,
.hide,
.hidden {
    display: none !important
}

.rel,
.relative {
    position: relative !important
}

.abs,
.absolute {
    position: absolute
}

.fixed {
    position: fixed
}

.t-0 {
    top: 0
}

.b-0 {
    bottom: 0
}

.l-0 {
    left: 0
}

.r-0 {
    right: 0
}

.tb-0 {
    top: 0;
    bottom: 0
}

.lr-0 {
    left: 0;
    right: 0
}

.v-mid,
.v-m {
    vertical-align: middle !important
}

.v-top,
.v-t {
    vertical-align: top !important
}

.v-bottom,
.v-b {
    vertical-align: bottom !important
}

.v-super {
    vertical-align: super !important
}

.full-input input,
.full-input select,
.full-input textarea {
    width: 100%
}

.normal input,
.normal select,
.normal textarea,
.full-input input[type=checkbox],
.full-input input[type=radio] {
    width: auto
}

.no-shadow {
    box-shadow: none !important
}

.no-border-radius {
    border-radius: 0 !important
}

.overflow-x-scroll {
    max-width: 100%;
    overflow-x: auto
}

.overflow-no {
    overflow: hidden
}

.overflow-auto {
    overflow: auto
}

.overflow-scroll {
    overflow: scroll
}

.relative {
    position: relative
}

.pointer {
    cursor: pointer
}

.fullwidth {
    width: 100%
}

.nolist {
    list-style: none
}

.radius3 {
    border-radius: 3px
}

.radius5 {
    border-radius: 5px
}

.radius10 {
    border-radius: 10px
}



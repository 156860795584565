@import url(./fonts/gotham/gotham.css);
@import url(./fonts/gotham-mac/gotham-mac.css);
@import url(./helper-spacing.css);

@font-face {
  font-family: "Zapfino";
  font-style: italic;
  font-weight: 400;
  src: local("Zapfino"), url("./fonts/Zapfino.woff") format("woff");
}

:root {
  --color-blue-main: #1f3564;
  --color-blue-medley: #0071ba;
  --color-blue-light: #29a9e0;
  --color-green-success: #39b44a;
  --color-secondary: #e92027;
  --color-red-error: #f0292f;
  --color-background: #f3f3f4;
  --color-gray-01: #8c8c8c;
  --color-gray-02: #989898;
  --color-border: #d0d0d0;
  --color-dark-gray: #68686a;
  --color-blue-collection: #1e355e;
  --color-border-hover: #1f3564;
  --main-height: calc(100vh - 74px);
  --white: #fff;
  --font-family: "Gotham";
  /* font-family Gotham for(linux and windows) Gotham-mac(mac)*/
  --fs-title: 2rem;
  --fs-sub-title-large: 1.375rem;
  --fs-sub-title-medium: 1.1875rem;
  --fs-sub-title-small: 1.0625rem;
  --fs-body-large: 1rem;
  --fs-body-medium: 0.9375rem;
  --fs-body-small: 0.875rem;
  --fs-body-smaller: 0.6875rem;
  --fs-text-indice: 0.5rem;
  /* remaxCollection */
  --color-blue-main-collector: #1e355e;
  --color-blue-secondary-collector: #0071ba;
  --list-max-width: 1270px;
}

html,
body,
#__next {
  height: 100%;
  /* overflow: auto; */
}

html {
  /* width: 100vw; */
  /* overflow-x: hidden; */
  /* overflow: hidden; */
}

#__next {
  isolation: isolate;
}

main {
  min-height: var(--main-height);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font-family);
  text-decoration: none;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;

  /* all: unset !important; */
}

.MuiChip-root span {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}

a {
  color: var(--color-blue-main);
}

body {
  background-color: var(--color-background) !important;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

h1,
h2,
h3 {
  font-weight: 700;
}

h2 {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.9rem;
  margin-bottom: 50px;
}

h3 {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.sup {
  vertical-align: super;
  font-size: 8px;
  color: inherit;
}

.mt-80 {
  padding-top: 80px;
}

@media screen and (min-width: 576px) {
  .container {
    max-width: 530px;
  }
}

@media screen and (min-width: 638px) {
  .container {
    max-width: 610px;
  }
}

@media screen and (min-width: 718px) {
  .container {
    max-width: 700px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media screen and (min-width: 868px) {
  .container {
    max-width: 820px;
  }
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1190px;
  }
}

@media screen and (min-width: 1300px) {
  .container {
    max-width: 1290px;
  }
}

.logo__link,
.logo__link:hover,
.logo__link:active,
.logo__link:visited,
.navbar_desktop__item_link,
.navbar_desktop__item_link:hover,
.navbar_desktop__item_link:active,
.navbar_desktop__item_link:visited {
  text-decoration: none;
  color: inherit;
}

.property_item__link,
.property_item__link:active,
.property_item__link:hover {
  text-decoration: none;
}

.collection__title,
.collection__text,
.collection__thumbnail_label,
.collection__card__description {
  color: #fff;
}

.collection__card_link,
.collection__card_link:hover,
.collection__card_link:active,
.collection__card_link:visited {
  text-decoration: none;
}

.franchise__link,
.projects__title,
.projects__text,
.projects__list_item,
.projects__list_title,
.projects__link {
  color: #fff;
}

.lending__btn_link:hover,
.lending__btn_link:active,
.lending__btn_link:visited,
.lending__btn_link,
.franchise__btn_link:hover,
.franchise__btn_link:active,
.franchise__btn_link:visited,
.franchise__btn_link,
.projects__link:hover,
.projects__link:active,
.projects__link:visited,
.projects__link {
  background-color: #e92027;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: 700;
}

/* Gallery image (lightbox) */
.pswp img {
  object-fit: contain;
}
